<template>
  <div v-loading="isLoading" class="sample">
    <div
      :class="[
        'container container--sample-screen',
        { mobileScreen: isMobile },
      ]"
    >
      <div class="sample__header">Шаблон суточных квот:</div>
      <template v-if="isShowQuotas">
        <transition name="el-fade-in-linear">
          <div>
            <SampleDay :day-data="samplesList" />
          </div>
        </transition>
      </template>

      <template v-if="!isShowQuotas && !isLoading">
        <transition name="el-fade-in-linear">
          <TableEmpty
            warning="У вас не создано ни одного шаблона суточной квоты"
            proposition="Добавить шаблон суточной квоты"
            @add="addSample"
          />
        </transition>
      </template>

      <dialog-add-sample />
    </div>
  </div>
</template>

<script>
import { DIALOG_ADD_SAMPLE } from '@/constants/dialogs'
import {
  FETCH_SAMPLES,
  GET_IS_LOADING_SAMPLES,
  GET_SAMPLES,
} from '@/views/quota-samples/store/actions'
import { GET_IS_MOBILE, GET_TERMINAL_CURRENT_ID } from '@/store/actions'
import { mapActions, mapGetters } from 'vuex'
import DialogAddSample from './components/dialogs/dialogAddSample/DialogAddSample.vue'
import SampleDay from './components/quota-day/SampleDay'
import TableEmpty from '@/UI/table/table-empty/TableEmpty.vue'
export default {
  name: 'QuotaSamples',
  components: {
    TableEmpty,
    DialogAddSample,
    SampleDay,
  },
  computed: {
    ...mapGetters({
      getTerminalCurrentId: GET_TERMINAL_CURRENT_ID,
      samplesList: GET_SAMPLES,
      isLoading: GET_IS_LOADING_SAMPLES,
      isMobile: GET_IS_MOBILE,
    }),
    isShowQuotas() {
      return this.samplesList.length
    },
  },
  watch: {
    getTerminalCurrentId() {
      this.getQuotas()
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getQuotas()
    })
  },
  methods: {
    ...mapActions({
      getQuotas: FETCH_SAMPLES,
    }),
    addSample() {
      this.setDialog({ name: DIALOG_ADD_SAMPLE, visible: true })
    },
  },
}
</script>

<style lang="sass" scoped>
.sample
  align-self: stretch
  flex: 1 0 100%
  .container--sample-screen
    width: 100%
    max-width: 1280px
    padding: 0 40px
    margin-bottom: 50px
  .mobileScreen
    padding: 0 16px 40px

  &__header
    margin: 22px 0 16px
    font-size: 24px
    font-weight: 700
    line-height: 36px
    color: $main-font

@media (max-width: 1199px)
  .sample .mobileHeader
    font-size: 22px
    font-weight: 500
</style>
