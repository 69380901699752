<template>
  <Dialog
    class="dialog-add-quota"
    :name="name"
    :show-close="!isEditSample"
    width="600px"
  >
    <template #title>
      <div>{{ getTitle }}</div>
    </template>

    <CreateSampleForm
      :sample="sample"
      :can-edit="!wasImproved"
      class="dialog-add-quota__body"
      @changed="setImproveMode"
    />
    <ShareSampleForm
      v-if="isEditSample"
      :sample="sample"
      :can-improve="!wasEdited"
      @improved="setEditMode"
    />
  </Dialog>
</template>

<script>
import CreateSampleForm from './components/CreateSampleForm'
import Dialog from '@/UI/dialog/Dialog'
import ShareSampleForm from './components/ShareSampleForm.vue'

import { DIALOG_ADD_SAMPLE } from '@/constants/dialogs'
export default {
  name: 'DialogAddSample',
  components: {
    Dialog,
    CreateSampleForm,
    ShareSampleForm,
  },
  data() {
    return {
      name: DIALOG_ADD_SAMPLE,
      wasEdited: false,
      wasImproved: false,
    }
  },
  computed: {
    sample() {
      return this.getDialog(this.name).data
    },
    isEditSample() {
      return Boolean(this.sample?.isEdit)
    },
    getTitle() {
      return this.isEditSample ? 'Обновление шаблона' : 'Добавление шаблона'
    },
  },
  methods: {
    // запрещаем распределение, если были изменения в редактировании, и наоборот
    setImproveMode(value) {
      this.wasEdited = value
    },
    setEditMode(value) {
      this.wasImproved = value
    },
  },
}
</script>

<style lang="sass" scoped>
.dialog-add-quota
  ::v-deep .el-dialog__body
    padding: 0 !important
  &__body
    padding: 20px 16px 16px
</style>
