var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      staticClass: "dialog-add-quota",
      attrs: {
        name: _vm.name,
        "show-close": !_vm.isEditSample,
        width: "600px",
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_c("div", [_vm._v(_vm._s(_vm.getTitle))])]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("CreateSampleForm", {
        staticClass: "dialog-add-quota__body",
        attrs: { sample: _vm.sample, "can-edit": !_vm.wasImproved },
        on: { changed: _vm.setImproveMode },
      }),
      _vm.isEditSample
        ? _c("ShareSampleForm", {
            attrs: { sample: _vm.sample, "can-improve": !_vm.wasEdited },
            on: { improved: _vm.setEditMode },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }