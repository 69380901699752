var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sample-day" }, [
    _c(
      "div",
      { staticClass: "sample-day__content" },
      _vm._l(_vm.dayData, function (item, index) {
        return _c(_vm.getCurrentComponent(item), {
          key: index,
          tag: "component",
          attrs: { sample: item },
          on: {
            updateBeforeDeleted: function ($event) {
              return _vm.$emit("updateBeforeDel")
            },
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }