var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "sample",
    },
    [
      _c(
        "div",
        {
          class: [
            "container container--sample-screen",
            { mobileScreen: _vm.isMobile },
          ],
        },
        [
          _c("div", { staticClass: "sample__header" }, [
            _vm._v("Шаблон суточных квот:"),
          ]),
          _vm.isShowQuotas
            ? [
                _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
                  _c(
                    "div",
                    [
                      _c("SampleDay", {
                        attrs: { "day-data": _vm.samplesList },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          !_vm.isShowQuotas && !_vm.isLoading
            ? [
                _c(
                  "transition",
                  { attrs: { name: "el-fade-in-linear" } },
                  [
                    _c("TableEmpty", {
                      attrs: {
                        warning:
                          "У вас не создано ни одного шаблона суточной квоты",
                        proposition: "Добавить шаблон суточной квоты",
                      },
                      on: { add: _vm.addSample },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c("dialog-add-sample"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }